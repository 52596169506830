@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.pageTitle {
  text-align: center;
  margin: 1vw auto 5px;
  font-size: 1.3rem;
}

eventify-yellow {
  font-weight: 700;
  color: var(--marketplaceColor);
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1620px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 10px auto;
  }
}
.contentWrapper {
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentSide {
  font-style: italic;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
  }
}

.contentMain {
  width: 90%;

  @media (--viewportMedium) {
    max-width: 650px;
  }
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  margin-top: 48px;
  margin-bottom: 24px;
}

.categories,
.categoriesBottom {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  grid-gap: 3vh;
  width: auto;
  margin: 3vh 0;
  height: auto;
}

.categoryBigger {
  display: block;
  align-items: center;
  flex-flow: nowrap;
  padding: 3vh 3vh 1vh;
  border-radius: 0.2vh;
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 0 0 10px 3px rgba(28, 28, 32, 0.03);
  transition: all 0.5s ease;
}

.categoryBigger:hover {
  transform: scale(1.06);
  transition: all 0.2s ease;
  box-shadow: 0 0 10px 3px rgba(28, 28, 32, 0.1);
}

.categoryBigger:active {
  transform: scale(1);
  transition: all 0.2s ease;
  box-shadow: 0 0 15px 5px rgba(28, 28, 32, 0.1);
}

.listSmaller,
.listBigger {
  list-style: none;
  margin: 1vh 0 2vh;
  padding: 0;
}

.titleSmaller {
  font-size: 20px;
  margin-bottom: 15px;
}

.subcategory,
.subcategorySmaller {
  color: #3b3b3b;
  font-size: 0.9rem;
  font-weight: 400;
}

.subcategorySmaller {
  font-size: 0.9rem;
  line-height: 1.4;
}

.subcategory:hover,
.subcategorySmaller:hover {
  color: var(--marketplaceColorDark);
}

.subcategoriesLink:hover {
  text-decoration: none !important;
  color: var(--marketplaceColorDark);
}

.count,
.countSmaller,
.countYellow,
.countSmallerYellow {
  color: #aaa !important;
  font-size: 0.7rem !important;
  margin-left: 3px !important;
}

.countYellow,
.countSmallerYellow {
  color: var(--marketplaceColor) !important;
  font-weight: 700 !important;
}

@media (min-width: 1260px) {
  .categories {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1660px) {
  .categories {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 900px) {
  .categories {
    grid-template-columns: 1fr;
  }
}

.bannerList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.bannerItem {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-position: 0 0;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  background-size: cover;

  @media (--viewportLarge) {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
    margin-bottom: 0;
  }
}

.bannerTitle {
  color: var(--matterColorBright);
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin: 0;
  width: 40%;
}

.bannerSubTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: var(--marketplaceColor);
  margin: 0;
  width: 50%;
}
