@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: inline-block;
}

.mobileRoot {
  width: 100%;
}

.label {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: 5px 18px;
  width: auto;
  height: 40px;
  min-height: 0;
  border-radius: 5px;

  width: 100%;

  @media (--viewportSmall) {
    width: auto;
  }

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

/*.labelSelected {*/
/*  @apply --marketplaceButtonStyles;*/
/*  @apply --marketplaceSearchFilterLabelFontStyles;*/

/*  padding: 5px 18px;*/
/*  width: auto;*/
/*  height: auto;*/
/*  min-height: 0;*/
/*  border-radius: 5px;*/
/*  background-color: #296151;*/
/*  border: 1px solid #296151;*/

/*  width: 100%;*/

/*  @media (--viewportSmall) {*/
/*    width: auto;*/
/*  }*/

/*  &:hover,*/
/*  &:focus {*/
/*     border: 1px solid #239954;*/
/*     background-color: #239954;*/
/*  }*/
/*}*/

.labelText {
  @apply --marketplaceH3FontStyles;

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;
  padding: 4px 0 2px 0;
  color: var(--matterColorDark);

}

.formWrapper {
  position: absolute;
  min-width: 260px;
  z-index: 50;
  top: 100%;
  background: var(--matterColorLight);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16), 0 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0 20px 13px rgba(0, 0, 0, 0.01), 0 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185), 0 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
  margin-top: 16px;
  right: 0;

  @media (--viewportSmall) {
    min-width: 300px;
    right: auto;
  }
}

.customFormWrapper {
  min-width: 260px;
  z-index: 50;
  padding: 15px 0;
  margin-top: 10px;
  border-bottom: 1px solid var(--matterColorNegative);
}

.formIsClosed {
  visibility: hidden;
  opacity: 0;
}

.formIsOpen {
  opacity: 1;
  visibility: visible;
}

.buttonsWrapper {
  display: flex;
  padding: 20px 0 0;
}

.mobileButtonsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clearButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  color: var(--matterPlaceholder);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  /* Layout */
  margin: 0 auto 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
     color: var(--matterColor);
     transition: width var(--transitionStyleButton);
   }
}

.mobileClearButton {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  display: inline-block;
  float: right;
  margin-top: 6px;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
     color: var(--matterColor);
   }
}

.cancelButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  color: var(--matterPlaceholder);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  /* Layout */
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
     color: var(--matterColor);
     transition: width var(--transitionStyleButton);
   }
}

.submitButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  color: var(--matterTomato);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  /* Layout */
  margin: 0 0 0 19px;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
     color: var(--marketplaceColorDark);
     transition: width var(--transitionStyleButton);
   }
}

.mobileSubmitButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 48px;
  margin-top: 20px;
}

.labelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
     border: 1px solid var(--marketplaceColorDark);
   }
}

.mobileLabelSelected {
  color: var(--marketplaceColor);

  &:hover,
  &:focus {
     color: var(--marketplaceColor);
   }
}

.locationHidden {
  display: none;
}

.locationVisible {
  display: block;
  padding-left: 20px;
}

